<template>
  <div class="flex-col page">
    <div class="flex-col section_1">
      <div class="flex-col text-wrapper">
        <span class="text_1">{{StateName}}工单</span>
      </div>
    </div>
    <div class="flex-col group_1">
      <div class="flex-col">
        <div class="justify-between group_3">
          <div class="flex-row">
            <div class="flex-row group_5">
              <span>工单派发</span>
              <span :class="['text_3',{text_color:State == 0}]">接单</span>
            </div>
            <span :class="['text_4',{text_color:State ==1}]">处理中</span>
          </div>
                    <span :class="['text_5',{text_color:State == 2 || State == 3}]">完成</span>

        </div>
        <div class="flex-col group_6">
          <div class="divider"></div>
          <span :key="i" v-for="(img, i) in imageList">
            <img :src="img.src" :class="'orderStateImage'+  i " />
          </span>
        </div>
       <div class="flex-row group_7">
          <div class="left-group"   v-show="State>=0">
            <span class="text_6">
              {{ReceiveTime}}
              <br />
            </span>
          </div>
          <div class="left-group view_1"  v-show="State>=1" >
            <span class="text_6">
              {{OrderTime}}
              <br />
            </span>
          </div>
          <div class="left-group view_1"  v-show="State>=2" >
            <span class="text_6">
              {{ArriveTime}}
              <br />
            </span>
          </div>
           <div class="left-group view_1"  v-show="State>=3" >
            <span class="text_6">
              {{SuccessTime}}
              <br />
            </span>
          </div>
        </div>
        <span class="text_12">报警工单</span>
      </div>
       <div class="list">
        <div class="list-item flex-row" :key="i" v-for="(title, i) in list">
          <span class="text_13">{{title.title}}</span>
          <span :class="['text_15',{red:title.key == 'Cause'},{red:title.key == 'StateName'}]" >{{title.value}}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import progressIconHistory from '@/assets/icon13.png' //历史图标
import progressIconNow     from '@/assets/icon15.png' //现在图标

import { Dialog } from 'vant'
import {detail} from '@/api/Property/Alarm/all'
export default {
  data() {
    return {
      list: [
        { title: '工单号'  ,  key:'AlarmId',value:'' },
        { title: '小区名称',  key:'HousesName',value:'' },
        { title: '电梯位置',  key:'address',value:'' },
        { title: '电梯名称',   key:'Number',value:'' },
        { title: '电梯注册码',  key:'RegisterNumber',value:'' },
        { title: '任务来源',  key:'Cause',value:'' },
        { title: '故障代码', key:'FailureCode',value:'' },
        { title: '告警内容', key:'EventDesc',value:'' },
        { title: '维保员'   , key:'CompanyStaffName',value:'' },
        { title: '维保人电话', key:'CompanyStaffPhone',value:'' },
        { title: '工单时间', key:'ReceiveTime',value:'' },
        { title: '工单状态', key:'StateName',value:'' },
      ],
      imageList:[
        { src: progressIconHistory },
        { src: progressIconHistory },
        { src: progressIconHistory },
        { src: progressIconHistory }
      ],
      State:0, //工单状态
      StateName:'', //工单状态
      ReceiveTime:'',//创建
      OrderTime:'',//处理
      ArriveTime:'',//处理中
      SuccessTime:'',//完成
    }
  },
  components: {
    [Dialog.Component.name]: Dialog.Component
  },
  mounted(){
    detail({id:String(this.$route.params.id)}).then(res=>{

        let resData = res.data.Data;

        let number = Number(resData.State) < 3 ? Number(resData.State) + 1 : 3;

        this.imageList[number].src   = progressIconNow;//更新当前进度条
        this.list        = this.$common.setList(this.list,resData) //更新列表值;
        this.State       = resData.State;
        this.StateName   = resData.StateName;

        this.ReceiveTime = resData.ReceiveTime;
        this.OrderTime   = resData.OrderTime;
        this.ArriveTime  = resData.ArriveTime;
        this.SuccessTime = resData.SuccessTime;

    })
  }
}
</script>

<style scoped>
@import '../../../assets/common.css';
.button {
  color: rgb(255, 255, 255);
  font-size: 1rem;
  line-height: 1rem;
  white-space: nowrap;
  padding: 1.06rem 0;
  background-image: url('../../../assets/buttonbg.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.list-item {
  padding-bottom: 1.13rem;
  border-bottom: solid 0.063rem rgb(243, 249, 244);
  height: 3rem;
}
.left-group {
  line-height: 0.81rem;
  text-align: center;
  width: 3.78rem;
  height: 1.63rem;
}
.text_13 {
  color: rgb(179, 179, 178);
  font-size: 0.75rem;
  line-height: 2rem;
  white-space: nowrap;
  display: inline-block;
  width: 5rem;
}
.text_15 {
  margin-left: 3.44rem;
  color: rgb(101, 102, 101);
  font-size: 0.88rem;
  line-height: 2rem;
  white-space: nowrap;
}
.text_6 {
  color: rgb(153, 153, 153);
  font-size: 0.69rem;
  line-height: 0.81rem;
}
.text_8 {
  color: rgb(153, 153, 153);
  font-size: 0.69rem;
  line-height: 0.81rem;
}
.page {
  padding-bottom: 1.56rem;
  background-color: rgb(255, 255, 255);
  height: 100%;
  width: 100%;
  overflow-y: auto;
}
.section_1 {
  padding: 0 0 1.69rem;
}
.group_1 {
  margin-top: -2.56rem;
  padding: 1.69rem 1.25rem 0;
  background: #fff;
  border-radius: 1rem;
}
.section_2 {
  padding: 0.88rem 0.81rem;
  color: rgb(0, 0, 0);
  font-size: 1rem;
  line-height: 1rem;
  white-space: nowrap;
  background-color: rgb(237, 237, 237);
}
.text-wrapper {
  padding: 1.88rem 0 3.44rem;
  color: rgb(255, 255, 255);
  font-size: 1.5rem;
  line-height: 1.44rem;
  white-space: nowrap;
  background-image: url(/img/imgbg1_03.7f7ef7cb.png);
  background-size: cover;
  background-repeat: no-repeat;
}
.list {
  margin-top: 1.56rem;
  padding-bottom: 1.06rem;
  height: 33.88rem;
  border-bottom: solid 0.063rem rgb(243, 249, 244);
}
.text-wrapper_1 {
  margin-top: 1.88rem;
}
.text-wrapper_2 {
  margin-top: 1.25rem;
}
.image_1 {
  margin-right: 0.25rem;
  align-self: center;
  width: 1.13rem;
  height: 0.25rem;
}

.group_3 {
  padding: 0 0.97rem 0.5rem 1.06rem;
}
.group_6 {
  padding: 0.44rem 0;
  position: relative;
}
.group_7 {
  margin-top: 0.56rem;
  padding: 0 0.94rem;
}
.text_12 {
  margin-top: 0.94rem;
  color: rgb(0, 0, 0);
  font-size: 1rem;
  line-height: 1rem;
  white-space: nowrap;
}
.image {
  margin-bottom: 0.13rem;
  width: 0.81rem;
  height: 0.81rem;
}
.text {
  margin-left: 0.69rem;
}
.text_1 {
  margin-left: 1.5rem;
}
.text_3 {
  margin-left: 2.97rem;
}
.text_4 {
  margin-left: 3.53rem;
  font-size: 0.69rem;
  line-height: 0.69rem;
  white-space: nowrap;
  color: rgb(153, 153, 153);
}
.text_5 {
  color: rgb(153, 153, 153);
  font-size: 0.69rem;
  line-height: 0.75rem;
  white-space: nowrap;
}

.text_color{
    color: rgb(62, 97, 164);
}

.divider {
  margin-left: 1.44rem;
  margin-right: 1.19rem;
  height: 0.063rem;
  border: solid 0.063rem rgb(153, 153, 153);
}
.orderStateImage0 {
  width: 0.88rem;
  height: 0.88rem;
  position: absolute;
  left: 0.94rem;
  top: 0.063rem;
}
.orderStateImage1 {
  width: 0.88rem;
  height: 0.88rem;
  position: absolute;
  left: 7.38rem;
  top: 0.063rem;
}
.orderStateImage2 {
  width: 0.88rem;
  height: 0.88rem;
  position: absolute;
  left: 12.63rem;
  top: 0;
}
.orderStateImage3 {
  width: 0.88rem;
  height: 0.88rem;
  position: absolute;
  right: 1rem;
  top: 0.063rem;
}
.view_1 {
  margin-left: 1.41rem;
}
.group_5 {
  color: rgb(153, 153, 153);
  font-size: 0.69rem;
  line-height: 0.69rem;
  white-space: nowrap;
}

.look {
  color: #005ddb;
}
.red {
  color: red;
}
.section_303 {
  padding: 0.4rem 0.6rem;
  border-radius: 0.2rem;
  border: 1px solid #ccc;
  overflow: auto;
  position: relative;
  margin: 2rem;
}
.image_9 {
  width: 0.4375rem;
  height: 0.375rem;
  position: absolute;
  top: 0.8rem;
  right: 0.4rem;
}
</style>